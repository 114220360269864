import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AddBill from '../../pages/AddBill';
import AppPartners from '../../pages/AppPartners';
import Home, { HomeType } from '../../pages/Home';
import Legals from '../../pages/Legals';

const legals = [
  'mentions',
  'cgu',
  'cookies',
  'confidentialite',
  'xpoAccountPolicy',
  'xpocardPolicy',
  'pricingDocumentation',
  'cguCodePromo'
] as const;
export type LegalsType = typeof legals[number];

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/download" element={<Home redirect />} />

        {/* marketing redirection preprod */}
        <Route path="/download/vp" element={<Home redirect type={HomeType.VP} />} />
        <Route path="/download/lbc" element={<Home redirect type={HomeType.LBC} />} />
        <Route path="/download/sp" element={<Home redirect type={HomeType.SP} />} />

        {/* marketing redirection prod */}
        <Route path="/veepee" element={<Home redirect type={HomeType.VP_PROD} />} />
        <Route path="/leboncoin" element={<Home redirect type={HomeType.LBC_PROD} />} />
        <Route path="/showroomprive" element={<Home redirect type={HomeType.SP_PROD} />} />

        <Route path="/sites-partenaires" element={<AppPartners />} />
        {legals.map((legal: LegalsType) => (
          <Route key={legal} path={`/${legal}`} element={<Legals legalType={legal} />} />
        ))}
        <Route path="/add-bill/:datamatrix" element={<AddBill />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
